<template>
  <div id="trigger">
    <audio id="audio" ref="audio" loop autoplay :src="audio_src">
    </audio>
    <div style="width: 100%;height: 30px;position: fixed;z-index: 99999;bottom: 14px;">
      <div style="width: 100%;height: 30px;margin: 0 auto;position: relative;z-index: 999;">
        <div class="btn_ipt" @click="show_ipt_box()"
          style="width: 80%;height: 30px;background-color: rgba(0, 0, 0,0.4);border-radius: 5px;position: absolute;left: 5%;text-align: left;">
          <a class="btn_ipt" style="margin-left: 15px;color: #ffffff;line-height: 30px;font-size: 14px;">留下你的祝福吧...</a>
        </div>
        <div style="width: 200px;height: 400px;position: absolute;right: 0;text-align: right;bottom: 0;z-index: 888;">
          <img id="like_btn" class="like_btn" src="../assets/like.png" width="40" alt="" srcset=""
            style="position: absolute;bottom: -4px;right: 18px;z-index: 999999;">
          <like></like>
        </div>
      </div>
    </div>
    <div v-show="ipt_box" id="ipt_box"
      style="width: 100%;height: 300px;position: fixed;z-index: 99999;top: 0;background-color: rgba(0, 0, 0,0.8);">
      <br />
      <a style="position: absolute;right: 20px;top:10px;color: #ffffff;" @click="show_ipt_box()">X</a>
      <a style="font-size: 18px;color: #ffffff;">祝福留言</a>
      <br />
      <input id="myInput" placeholder="您的大名" autofocus="autofocus" type="text"
        style="text-indent: 10px;width: 80%;height: 40px;outline: 0;border: 0;border-radius: 5px;font-size: 16px;padding: 0;margin-top: 24px;">
      <br />
      <textarea placeholder="请开始你的祝福吧..."
        style="text-indent: 10px;width: 80%;height: 80px;outline: 0;border: 0;border-radius: 5px;font-size: 16px;padding: 10px 0 0 0;margin-top: 8px;"></textarea>
      <br />
      <div @click=" show_ipt_box()"
        style="width: 80%;height: 40px;outline: 0;border: 0;border-radius: 5px;font-size: 18px;color: #ffffff;text-align: center;margin: 0 auto;background: linear-gradient(to bottom right, rgb(236, 78, 125), rgb(223, 38, 69));margin-top: 8px;">
        <a style="line-height: 40px;">提交</a>
      </div>
    </div>
    <div class="top-1">
      <div class="top-bar">
        <a style="float: left;">WELCOME TO OUR WEDDING</a>
        <a style="float: right;">2024/01/28</a>
        <a style="position: absolute;top: 16px;left: 0;">欢迎参加我们的婚礼</a>
      </div>
      <a class="title">我们要结婚啦</a>
      <a class="title-describe">We're getting married</a>
      <img style="width: 100%;"
        src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/P001.jpg?x-oss-process=style/yasuo">
      <a v-if="show_time" class="title-time">婚礼倒计时</a>
      <a v-if="!show_time" class="title-time">婚礼进行中...</a>
      <div class="timer-container">
        <div class="timer-item">
          <span class="timer-value">{{ days }}</span>
          <span class="timer-unit">天</span>
        </div>
        <div class="timer-item">
          <span class="timer-value">{{ hours }}</span>
          <span class="timer-unit">时</span>
        </div>
        <div class="timer-item">
          <span class="timer-value">{{ minutes }}</span>
          <span class="timer-unit">分</span>
        </div>
        <div class="timer-item">
          <span class="timer-value">{{ seconds }}</span>
          <span class="timer-unit">秒</span>
        </div>
      </div>
      <div style="position: absolute;bottom: 0px;width: 100%;margin: 0 auto;font-size: 18px;font-family: '阿里巴巴';">
        <a>2024年01月28日</a>
        <br />
        <a>农历腊月十八 癸卯 乙丑 辛卯</a>
      </div>
    </div>
    <img style="width: 100%;" src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/latte.webp">
    <div class="content">
      <div class="content-first">
        <a style="color: rgb(119, 119, 0);font-size: 28px;font-family: '电子表';">2019——2024</a>
        <br />
        <a>一路走来 成为朋友 成为知己 成为伴侣</a>
        <br />
        <br />
        <a>我们是彼此亲自挑选的家人<br /><br />从今往后 开启新的生活<br />像一首奇妙的音乐<br />希望这份幸福传递给你</a>
        <br />
        <br />
        <a style="color: rgb(119, 119, 0);font-size: 24px;"><b>王吕&李倩</b></a>
        <br />
        <br />
        <a>偕同父母家人<br />邀请您见证和分享我们的幸福时刻</a>
        <br /><br />
        <img style="width: 90%;"
          src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9872.jpg?x-oss-process=style/yasuo" />
        <div style="text-align: left;width: 90%;margin: 0 auto;">
          <div>在四季徐徐变幻间</div>
          <div>我们手牵手</div>
          <div>走过春花秋月冬雪</div>
          <div>共度<a style="font-size: 24px;font-family: '江城律动黑';">{{ all_day }}</a>个晨昏</div>
        </div>
        <br />
        <div style="position: relative;width: 90%;margin: 0 auto;height: 340px;">
          <a
            style="color: rgba(77, 77, 0, 0.8);position: absolute;right: 0;z-index: 9999;top: 30px;font-family: '江城律动黑';font-size: 20px;">{{
              all_hour }}<br />小时</a>
          <a
            style="color: rgba(77, 77, 0, 0.8);position: absolute;left: 0;z-index: 9999;bottom: 70px;;font-family: '江城律动黑';font-size: 20px;">{{
              all_min }}<br />分钟</a>
          <img style="width: 70%;position: absolute;left: 0;top: 0;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9740.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
          <img
            style="width: 70%;position: absolute;right: 0;box-sizing: border-box;bottom: 30px;border: 10px solid #ffffff;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9815.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
        </div>
        <div
          style="width: 90%; margin: 0 auto; text-align: center;word-spacing: 20px; font-size: 50px; font-family: '电子表';">
          {{ all_sec }}<a style="font-family: '阿里巴巴'; font-size: 30px;">秒</a>
        </div>
        <br />
        <div style="width: 90%;margin: 0 auto;">
          <a
            style="top: 0;text-align: left;display: block;background-color: rgba(109, 62, 62, 0);right: 0;z-index: 999;padding: 10px;">
            <a style="font-size: 24px;font-family: '江城律动黑';">19年9月20号</a><br />
            那是我们缘分开始的时刻<br />
            从那一天起 我们开始融入彼此的人生<br />
            成为了彼此的依靠 携手共渡爱情的四季<br />
          </a>
        </div>
        <div style="position: relative;width: 90%;margin: 0 auto;">
          <img style="border: 10px solid #ffffff;width: 100%;box-sizing: border-box;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9793.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
        </div>
        <div style="width: 90%;margin: 0 auto;">
          相处的日子里<br />
          我们或许都深刻地明白<br />
          真诚的爱情需要双方共同奔赴<br />
          需要包容与改变<br />
          更是一场学会如何去爱的深刻过程<br />
        </div>
        <div style="position: relative;width: 90%;margin: 0 auto;">
          <img style="border: 10px solid #ffffff;width: 100%;box-sizing: border-box;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9777.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
        </div>
        <div style="width: 90%;margin: 0 auto;">
          还记得初次合影的那一刻吗？<br />
          那是我勇敢跨越山河<br />
          寻觅你的的冒险之旅<br />
          合照中的那一刻 是心动的开始<br />
        </div>
        <br />
        <br />
        <div style="width: 90%;margin: 0 auto;height: 350px;position: relative;">
          <img style="width: 45%;position: absolute;top: 0;left: 0;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9821.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
          <img style="width: 45%;position: absolute;bottom: 0;right: 0;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9809.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
        </div>
        <div style="width: 90%;margin: 0 auto;text-align: left;">
          回首往事<br />
          我们的相遇如同世界上最美妙的巧合<br />
          命运巧妙地将我们牵引在一起<br />
          在生活的旅途中<br />
          我们跋山涉水<br />
          共同成长 大步向前<br />
        </div>
        <br />
        <div style="position: relative;width: 90%;margin: 0 auto;">
          <img style="border: 10px solid #ffffff;width: 100%;box-sizing: border-box;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9854.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
        </div>
        <br />
        <div style="width: 90%;margin: 0 auto;">
          未来的路上<br />
          愿我们的生活充满温馨和幸福<br />
          共度更多春夏秋冬<br />
          度过更多日夜<br />
          执子之手 与之偕老<br />
          让爱情的旅途永远如初 如诗 如画<br />
        </div>
        <br />
        <div style="position: relative;width: 90%;margin: 0 auto;">
          <a
            style="font-size: 24px;position: absolute;display: block;width: 100%;text-align: center;color: rgb(253, 228, 2);bottom: 105px;font-weight: bold;content: '王吕和李倩的婚礼即将上线';-webkit-text-stroke-width: 0;font-family: '江城律动黑';"><b>王吕&李倩&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;婚礼即将上线</b></a>
          <img style="width: 100%;"
            src="https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9993.jpg?x-oss-process=style/yasuo" alt=""
            srcset="">
        </div>
        <br /><br />
        <div style="width: 90%;margin: 0 auto;position: relative;height: 200px;">
          <div class="calendar">
            <div class="data-title">
              <a class="green">{{ my_year }}年</a>
              &nbsp;
              <a class="green">{{ month_name[my_month] }}</a>
              <!-- <a href="" @click.prevent="prevMonth" id="pre"></a>
            <a href="" @click.prevent="nextMonth" id="next"></a> -->
            </div>

            <div class="data-body">
              <div class="lightgrey data-body-list">
                <ul>
                  <li>日</li>
                  <li>一</li>
                  <li>二</li>
                  <li>三</li>
                  <li>四</li>
                  <li>五</li>
                  <li>六</li>
                </ul>
              </div>

              <div class="darkgrey data-body-list">
                <ul id="days">
                  <li v-for="(day, index) in calendarDays" :key="index" :class="day.class">{{ day.day }}</li>
                </ul>
              </div>
            </div>

          </div>
          <div class="timer-container-h">
            <div class="timer-item-h">
              <span class="timer-value-h">{{ days }}</span>
              <span class="timer-unit-h">天</span>
            </div>
            <div class="timer-item-h">
              <span class="timer-value-h">{{ hours }}</span>
              <span class="timer-unit-h">时</span>
            </div>
            <div class="timer-item-h">
              <span class="timer-value-h">{{ minutes }}</span>
              <span class="timer-unit-h">分</span>
            </div>
            <div class="timer-item-h">
              <span class="timer-value-h">{{ seconds }}</span>
              <span class="timer-unit-h">秒</span>
            </div>
          </div>
        </div>
        <br /><br /> <br /><br />
        <div style="width: 90%;margin: 0 auto;position: relative;">
          <div style="padding-top: 20px;padding-bottom: 20px;">
            🏠男方地址:安顺市镇宁县募役蜜蜂岩<br />
            🏠女方地址:遵义市湄潭县天城镇李家堰村
            <br />
          </div>
          <MapContainer></MapContainer>
          <!-- latitude:0,
      longitude:0,
      map_name:'',
      map_address:'', -->
          <div style="width: 100%;margin: 0 auto;display: flex;justify-content:center;margin-top: 10px;">
            <button @click="openlocation(25.963068, 105.81299, '新郎王吕家', '贵州省安顺市镇宁布依族苗族自治县募役乡新发村蜜蜂岩二组')"
              style="width: 120px;height: 30px;font-size: 14px;color: rgba(255, 255, 255, 1);background-color: rgba(148, 141, 144, 1);border: none;">导航新郎家</button>
            <span style="display: block;width: 60px;"></span>
            <button @click="openlocation(27.797729, 107.618149, '新娘李倩家', '遵义市湄潭县天城乡幸福路李家堰村村委会')"
              style="width: 120px;height: 30px;font-size: 14px;color: rgba(255, 255, 255, 1);background-color: rgba(148, 141, 144, 1);border: none;">导航新娘家</button>
          </div>
          <div style="width: 100%;margin: 0 auto;display: flex;justify-content:center;margin-top: 10px;color: #ffffff;">
            <a style="color: #f75b5b;text-decoration: none;" href="tel:16685034116">联系新郎</a>
            <span style="display: block;width: 120px;"></span>
            <a style="color: #f75b5b;text-decoration: none;z-index: 99999;" href="tel:17385793963">联系新娘</a>
          </div>

        </div>
        <div style="height: 200px;width: 90%;margin: 0 auto;margin-top: 40px;font-size: 18px;font-weight: bold;">
          🌺良辰已定，吉日待访<br />
          🥂吾有薄酒，以谢良友<br />
          🍱敬备喜筵，随候尊驾<br />
          ❤诚邀您见证我们的幸福
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import MapContainer from "../components/gaodemap/MapContainer.vue"
import MapContainer2 from "../components/gaodemap/MapContainer2.vue"
import Gallery from "../components/other/Gallery.vue"
import TencentMap from "../components/tx/TencentMap.vue"
import like from "../components/like/like.vue"
import wx from 'jweixin-module';
import axios from 'axios';


export default {
  components: {
    MapContainer, Gallery, MapContainer2, TencentMap, like
  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      audio_src: 'https://wanglv.oss-cn-hangzhou.aliyuncs.com/music/sjmhynhhxk.mp3',
      weddingDate: new Date('2024-01-28'),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      show_time: false,
      all_day: 0,
      all_hour: 0,
      all_min: 0,
      all_sec: 0,
      month_olypic: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      month_normal: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      month_name: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
      holder: document.getElementById("days"),
      my_date: new Date(2024, 0, 28), // Locked to January 28, 2024
      my_year: 2024,
      my_month: 0,
      my_day: 28,
      isWeChat: false,
      latitude: 0,
      longitude: 0,
      map_name: '',
      map_address: '',
      els: '#trigger',
      ipt_box: false,
      isTouching: false,
      position: 0,
      TouchCount: 0,
      screenHeight: window.innerHeight,

      isDown: false,
      userAgent: navigator.userAgent,
      ua: navigator.userAgent.toLowerCase(),
      WeChat: navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1,
    }
  },
  computed: {
    calendarDays() {
      let days = [];
      const totalDay = this.daysMonth(this.my_month, this.my_year);
      const firstDay = this.dayStart(this.my_month, this.my_year);

      for (let i = 0; i < firstDay; i++) {
        days.push({ day: "", class: "" });
      }

      for (let i = 1; i <= totalDay; i++) {
        let myclass = "";
        if (
          this.my_year < this.my_date.getFullYear() ||
          (this.my_year === this.my_date.getFullYear() && this.my_month < this.my_date.getMonth()) ||
          (this.my_year === this.my_date.getFullYear() && this.my_month === this.my_date.getMonth() && i < this.my_day)
        ) {
          myclass = "lightgrey";
        } else if (this.my_year === this.my_date.getFullYear() && this.my_month === this.my_date.getMonth() && i === this.my_day) {
          myclass = "green greenbox";
        } else {
          myclass = "darkgrey";
        }

        days.push({ day: i, class: myclass });
      }

      return days;
    },
  },
  mounted() {
    let trigger = document.getElementById('trigger')
    let audio = document.getElementById('audio')
    trigger.addEventListener('touchstart', () => {
      audio.play()
    })

    // 模拟触发 「touchstart」 事件
    this.createTouchstartEventAndDispatch(trigger);

    function audioAutoPlay(id) {
      var audio = document.getElementById(id),
        play = function () {
          audio.play();
          document.removeEventListener("touchstart", play, false);
        };
      audio.play();
      document.addEventListener("WeixinJSBridgeReady", function () {
        play();
      }, false);
    }
    audioAutoPlay('audio');

    this.handleImgClick();
  },
  beforeDestroy() {
  },
  methods: {
    handleImgClick() {
      document.getElementById('like_btn').addEventListener('click', ImgClick, true);
      function ImgClick(event) {
      }
    },
    show_ipt_box() {
      this.ipt_box = !this.ipt_box;

      if (this.ipt_box == false) {
        this.isTouching = false;
        // 调用动画函数
        this.animateScroll();
        return;
      }

      this.isTouching = true;

      var newInput = document.createElement('input');
      newInput.type = 'text';
      newInput.style.background = 'rgba(0,0,0,0)';
      newInput.style.position = 'fixed';
      newInput.style.top = '0';
      newInput.style.border = '0';
      newInput.style.outline = '0';
      newInput.style.padding = '0';
      newInput.style.width = '0';
      newInput.style.fontSize = '0';

      // 在 document 中追加新的 input 元素
      document.body.appendChild(newInput);
      // 隐藏新创建的 input 元素

      // 设置焦点到新的 input 元素
      newInput.focus();
      setTimeout(function () {
        var originalInput = document.getElementById('myInput');
        originalInput.focus();
        // 移除新创建的 input 元素
        document.body.removeChild(newInput);
      }, 300); // 设置一个适当的延时

    },
    animateScroll() {
      let pageHeight = document.documentElement.scrollHeight;
      if (!this.isTouching) {
        this.position++;
        this.isDown = true;
        if (this.WeChat) {
          // 如果在微信中，进行微信JS-SDK配置
          if (/Android/i.test(this.userAgent)) {
            // 安卓设备
            document.body.scrollTop = this.position;
          } else if (/iPhone|iPad|iPod/i.test(this.userAgent)) {
            // iOS设备
            document.documentElement.scrollTop = this.position;
          } else {
            // 其他设备
            document.documentElement.scrollTop = this.position;
          }
        } else {
          // 如果不在微信中，可以进行其他处理
          document.documentElement.scrollTop = this.position;
        }

        if (Math.abs(this.position) >= pageHeight) {
          console.log("pageHeight", pageHeight);
          console.log("stop-1");
          this.isTouching = true;
          this.isDown = false;
          return;
        }
        // 使用this来调用requestAnimationFrame，并传递当前函数本身
        requestAnimationFrame(() => this.animateScroll());
        return;
      } else {
        console.log("stop");
        this.isTouching = true;
        this.isDown = false;
        return;
      }
    },
    sync_postion() {
      this.position = document.documentElement.scrollTop;
      if (this.WeChat) {
        // 如果在微信中，进行微信JS-SDK配置
        if (/Android/i.test(this.userAgent)) {
          // 安卓设备
          this.position = document.body.scrollTop;
        } else if (/iPhone|iPad|iPod/i.test(this.userAgent)) {
          // iOS设备
          this.position = document.documentElement.scrollTop;
        } else {
          // 其他设备
          this.position = document.documentElement.scrollTop;
        }
      } else {
        // 如果不在微信中，可以进行其他处理
        this.position = document.documentElement.scrollTop;
      }
    },
    Autoscroll() {
      let isMoved = false;

      const onTouchStart = (event) => {
        // console.log("记录触摸开始时的位置等信息");
      };

      const onTouchMove = (event) => {
        // 在触摸移动事件中设置isMoved为true
        this.isMoved = true;
        // 处理拖动逻辑
        if (!this.ipt_box) {
          const target = event.target;
          // 检查点击的元素是否是子元素
          if (target.id == "like_btn" || target.className == "btn_ipt") {

          } else {
            this.isTouching = true;
            this.position = document.documentElement.scrollTop;
            if (this.WeChat) {
              // 如果在微信中，进行微信JS-SDK配置
              if (/Android/i.test(this.userAgent)) {
                // 安卓设备
                this.position = document.body.scrollTop;
              } else if (/iPhone|iPad|iPod/i.test(this.userAgent)) {
                // iOS设备
                this.position = document.documentElement.scrollTop;
              } else {
                // 其他设备
                this.position = document.documentElement.scrollTop;
              }
            } else {
              // 如果不在微信中，可以进行其他处理
              this.position = document.documentElement.scrollTop;
            }
          }
        }

      };
      const onTouchEnd = (event) => {
        // 处理点击逻辑
        if (!this.isMoved) {
          console.log("处理点击逻辑");
          //执行点击逻辑
          if (!this.ipt_box) {
            const target = event.target;
            // 检查点击的元素是否是子元素
            //  console.log(target);
            if (target.id === 'like_btn' || target.closest('#ipt_box') || target.tagName.toLowerCase() === 'input') {
            } else if (target.className == "btn_ipt") {
              if (this.isTouching == true) {
                this.sync_postion();
                this.isTouching = false;
                this.animateScroll(); // 重新启动动画
              }
            } else {
              // 在这里可以执行父元素或其他地方被点击时的操作
              if (this.isTouching == true) {
                this.sync_postion();
                console.log(this.isDown);
                this.isTouching = false;
                this.animateScroll(); // 重新启动动画
              } else if (this.isTouching == false) {
                this.isTouching = true;
              }
            }
          }

        }
        // 重置isMoved为false，准备下一次触摸操作
        this.isMoved = false;
      };
      const removeEventListeners = () => {
        // 在组件销毁前移除事件监听，防止内存泄漏
        document.removeEventListener('touchstart', onTouchStart);
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
      };

      // 注册触摸事件到document
      document.addEventListener('touchstart', onTouchStart);
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);


      // 调用动画函数
      this.animateScroll();

    },
    // 判断是否在微信中
    checkWeChat() {
      const ua = navigator.userAgent.toLowerCase();
      this.isWeChat = ua.indexOf('micromessenger') !== -1;

      if (this.isWeChat) {
        // 如果在微信中，进行微信JS-SDK配置
        this.openlocation();
        return true;
      } else {
        // 如果不在微信中，可以进行其他处理

        return false;
      }
    },
    generateNonceStr(length) {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset.charAt(randomIndex);
      }
      return result;
    },
    openlocation(latitude, longitude, map_name, map_address) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.map_name = map_name;
      this.map_address = map_address;
      const ua = navigator.userAgent.toLowerCase();
      this.isWeChat = ua.indexOf('micromessenger') !== -1;

      if (this.isWeChat) {
        // 如果在微信中，进行微信JS-SDK配置
        const appId = 'wx5da16164a92a55de'; // 替换成你的微信公众号AppID
        const timestamp = Math.floor(new Date().getTime() / 1000);

        const nonceStr = this.generateNonceStr(16); // 替换成随机字符串
        const url = window.location.href.split('#')[0];
        // 调用后端接口获取签名等信息
        this.fetchSignature(url, appId, timestamp, nonceStr)
          .then((signature) => {
            wx.config({
              beta: true,
              debug: false,
              appId: appId,
              timestamp: timestamp,
              nonceStr: nonceStr,
              signature: signature,
              jsApiList: ["openLocation"], // 需要使用的微信JS-SDK接口，可以根据实际需求添加其他接口
            });
            console.log('signature', signature);
            wx.ready(() => {
              // 微信JS-SDK验证成功后的处理
              console.log('微信JS-SDK验证成功');
              this.launchMapNavigation();
            });

            wx.error((res) => {
              // 微信JS-SDK验证失败后的处理
              console.error('微信JS-SDK验证失败', res);
              alert('微信JS-SDK验证失败' + JSON.stringify(res));
            });
          })
          .catch((error) => {
            console.error('Failed to fetch signature:', error);
            alert('Failed to fetch signature:' + error);
          });
        return true;
      } else {
        // 如果不在微信中，可以进行其他处理2jtmxlpGET9pQDeasAKhzyEMXlZDVkN
        var qqMapUrl = 'qqmap://map/routeplan?type=drive&to=' + latitude + ',' + longitude;
        window.location.href = 'qqmap://map/routeplan?type=drive&from=清华&fromcoord=39.994745,116.247282&to=怡和世家&tocoord=39.867192,116.493187&referer=2jtmxlpGET9pQDeasAKhzyEMXlZDVkN';
        return false;
      }

    },

    // 获取签名信息
    async fetchSignature(url, appId, timestamp, nonceStr) {
      try {
        const response = await axios.get(`https://WxSignature.wanglv.top/getSignature?url=${encodeURIComponent(url)}&timestamp=${encodeURIComponent(timestamp)}&nonceStr=${encodeURIComponent(nonceStr)}`);
        // WxSignature.wanglv.top
        console.log("response", response);
        return response.data.signature;
      } catch (error) {
        throw new Error(`Failed to fetch signature1: ${error}`);
      }
    },

    // 拉起地图导航
    launchMapNavigation() {
      wx.openLocation({
        // 地理位置的坐标点，经纬度
        latitude: this.latitude,
        longitude: this.longitude,
        name: this.map_name,
        address: this.map_address,
        scale: 14,
        success(res) {
        },
        fail(res) {
          alert(JSON.stringify(res));
        },
      });

    },
    dayStart(month, year) {
      const tmpDate = new Date(year, month, 1);
      return tmpDate.getDay();
    },
    daysMonth(month, year) {
      const tmp1 = year % 4;
      const tmp2 = year % 100;
      const tmp3 = year % 400;

      if ((tmp1 === 0 && tmp2 !== 0) || tmp3 === 0) {
        return this.month_olypic[month];
      } else {
        return this.month_normal[month];
      }
    },
    refreshDate() {
      // You can implement this if needed
    },
    prevMonth() {
      this.my_month--;
      if (this.my_month < 0) {
        this.my_year--;
        this.my_month = 11;
      }
      // Optionally, you can call refreshDate() here
    },
    nextMonth() {
      this.my_month++;
      if (this.my_month > 11) {
        this.my_month = 0;
        this.my_year++;
      }
      // Optionally, you can call refreshDate() here
    },
    createTouchstartEventAndDispatch(el) {
      let event = document.createEvent('Events')
      event.initEvent('touchstart', true, true)
      el.dispatchEvent(event)
    },
    updateCountdown() {
      const currentTime = new Date();
      const timeDiff = this.weddingDate - currentTime;

      this.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    },
    updateAllsec() {
      let together = new Date('2019-09-20');
      this.all_sec = Math.floor((new Date() - together) / 1000);
    }
  },
  created() {
    const currentTime = new Date();

    if (currentTime <= this.weddingDate) {
      this.show_time = true;
      this.updateCountdown();
      setInterval(this.updateCountdown, 1000); // 每秒钟更新一次倒计时
    }

    let together = new Date('2019-09-20');
    this.all_day = Math.round(Math.abs((currentTime - together) / (24 * 60 * 60 * 1000)));
    this.all_hour = Math.round(Math.abs((currentTime - together) / (60 * 60 * 1000)));
    this.all_min = Math.round(Math.abs((currentTime - together) / (60 * 1000)));
    setInterval(this.updateAllsec, 1000);

    setTimeout(() => {
      this.Autoscroll();
    }, 500);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#trigger {
  overflow: hidden;
  position: relative;
}

.top-1 {
  position: relative;
  top: 0;
  left: 0;
}

.top-bar {
  background-color: rgba(112, 88, 88, 0);
  position: absolute;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
}


@font-face {
  font-family: "江城律动黑";
  src: url(../assets/font/江城律动黑.ttf);
}

@font-face {
  font-family: "电子表";
  src: url(../assets/font/DS-DIGI-1.ttf);
}

@font-face {
  font-family: "阿里巴巴";
  src: url(../assets/font/AlibabaPuHuiTi-3-65-Medium.ttf);
}


@font-face {
  font-family: "千图笔锋手写体";
  src: url(../assets/font/QianTuBiFengShouXieTi-2.ttf);
}

@font-face {
  font-family: "PostNoBillsColombo";
  src: url(../assets/font/PostNoBillsColombo-ExtraBold.ttf);
}


.top-1>img {
  width: 100%;
}

.title {
  position: absolute;
  top: 60px;
  font-family: "千图笔锋手写体";
  display: block;
  width: 100%;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 43px;
}

.title-describe {
  position: absolute;
  top: 110px;
  display: block;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-family: "PostNoBillsColombo";
}

.title-time {
  position: absolute;
  bottom: 170px;
  display: block;
  width: 100%;
  text-align: center;
  color: rgba(148, 141, 144, 0.8);
  font-size: 30px;
  font-family: "江城律动黑";
}

.title-timeed {
  position: absolute;
  bottom: 100px;
  display: block;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 50px;
  font-family: "江城律动黑";
}

.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  bottom: 70px;
  position: absolute;
  width: 100%;
}

.timer-container-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  bottom: 70px;
  position: absolute;
  right: 0;
  top: 0;
  height: 260px;
}

.timer-item {
  width: 80px;
  height: 80px;
  border: 1px dotted rgba(148, 141, 144, 0.8);
}

.timer-item-h {
  width: 60px;
  height: 60px;
  border: 1px solid rgba(148, 141, 144, 0.8);
}

.timer-value {
  display: block;
  font-size: 35px;
  /* color: rgb(235, 0, 0); */
  color: rgba(148, 141, 144, 0.8);
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

.timer-value-h {
  display: block;
  font-size: 20px;
  /* color: rgb(235, 0, 0); */
  color: rgba(148, 141, 144, 0.8);
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

.timer-unit {
  display: block;
  /* background-color: rgba(218, 3, 3, 0.8); */
  background-color: rgba(148, 141, 144, 0.8);
  width: 90%;
  margin: 0 auto;
  color: #ffffff;
}

.timer-unit-h {
  display: block;
  /* background-color: rgba(218, 3, 3, 0.8); */
  background-color: rgba(148, 141, 144, 0.8);
  width: 90%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 12px;
}

.content {
  width: 100%;
  color: #757575;
  font-size: 16px;
  background: linear-gradient(to bottom, #ffffff 0, #fcebeb 100%);
}

.content-first {
  width: 100%;
}

.calendar {
  width: 80%;
  height: 260px;
  background: rgba(54, 40, 40, 0);
  position: relative;
  border-radius: 0px;
  border-color: rgba(148, 141, 144, 0.8);
  border-style: solid;
  border-width: 1px;
  left: 0;
}

/*标题的显示样式*/
.data-title {
  margin-top: 20px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
  font-size: 20px;
}

/*标题中的月份显示样式*/
#calendar-data-title {
  font-size: 25px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

/*标题中的年份显示样式*/
#calendar-year {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

/*上个月的超链接显示样式*/
#pre {
  position: absolute;
  top: 0px;
  left: 0px;
  /* background: url(./assets/logo.png) no-repeat 50% 50%; */
  /*没规定大小时，图片显示 0X0*/
  width: 60px;
  height: 70px;
}

/*下个月的超链接显示样式*/
#next {
  position: absolute;
  top: 0px;
  right: 0px;
  /* background: url(./assets/logo.png) no-repeat 50% 50%; */
  width: 60px;
  height: 70px;
}

.data-body {
  position: absolute;
}

.data-body-list ul {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  width: 90%;
  box-sizing: border-box;

}

.data-body-list ul li {
  list-style: none;
  display: block;
  width: 14.28%;
  float: left;
  /*规定行高，垂直居中*/
  height: 28px;
  line-height: 28px;
  box-sizing: border-box;
  text-align: center;
}

/*类选择器：设置所有class属性为green的标签的样式*/
.green {
  color: #6ac13c;
}

/*设置已经过去的日期颜色*/
.lightgrey {
  color: #a8a8a8;
}

/*设置将来的日期颜色*/
.darkgrey {
  color: #565656;
}

/*日期当天用绿色背景绿色文字加以显示*/
.greenbox {
  background-image: url("https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/爱心.png");
  background-size: cover;
  /* or background-size: contain; */
}


.like_btn:hover,
.like_btn:focus {
  transform: scale(0.97);
  -ms-transform: rotate(0.97);
  /* IE 9 */
  -moz-transform: rotate(0.97);
  /* Firefox */
  -webkit-transform: rotate(0.97);
  /* Safari 和 Chrome */
  -o-transform: rotate(0.97);
  /* Opera */
}
</style>
