<template>
    <div>
        <canvas ref="thumbsCanvas" width="200" height="400"></canvas>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            imgsList: [],
            context: null,
            width: 0,
            height: 0,
            scanning: false,
            renderList: [],
            scaleTime: 0.1,
        };
    },
    mounted() {
        this.loadImages();
        this.context = this.$refs.thumbsCanvas.getContext('2d');
        this.width = this.$refs.thumbsCanvas.width;
        this.height = this.$refs.thumbsCanvas.height;
        setInterval(() => {
            this.start();
        }, 300);
    },
    methods: {
        handleClick(event) {
            event.stopPropagation();
        },
        getRandom(min, max) {
            return min + Math.floor(Math.random() * (max - min + 1));
        },
        loadImages() {
            const images = [
                'jfs/t1/93992/8/9049/4680/5e0aea04Ec9dd2be8/608efd890fd61486.png',
                'jfs/t1/108305/14/2849/4908/5e0aea04Efb54912c/bfa59f27e654e29c.png',
                'jfs/t1/98805/29/8975/5106/5e0aea05Ed970e2b4/98803f8ad07147b9.png',
                'jfs/t1/94291/26/9105/4344/5e0aea05Ed64b9187/5165fdf5621d5bbf.png',
                'jfs/t1/102753/34/8504/5522/5e0aea05E0b9ef0b4/74a73178e31bd021.png',
                'jfs/t1/102954/26/9241/5069/5e0aea05E7dde8bda/720fcec8bc5be9d4.png',
            ];

            const promiseAll = images.map((src) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onerror = img.onload = resolve.bind(null, img);
                    img.src = 'https://img12.360buyimg.com/img/' + src;
                });
            });

            Promise.all(promiseAll).then((imgsList) => {
                this.imgsList = imgsList.filter((d) => d && d.width > 0);
                if (this.imgsList.length === 0) {
                    console.error('imgsList load all error');
                }
            });
        },
        createRender() {
            if (this.imgsList.length === 0) return null;

            const basicScale = [0.2, 0.5, 0.7][this.getRandom(0, 2)];

            const getScale = (diffTime) => {
                if (diffTime < this.scaleTime) {
                    return +((diffTime / this.scaleTime).toFixed(2)) * basicScale;
                } else {
                    return basicScale;
                }
            };

            const context = this.context;
            const image = this.imgsList[this.getRandom(0, this.imgsList.length - 1)];
            const offset = 20;
            const basicX = 160;
            // const basicX = this.width/2 + this.getRandom(-offset, offset)+100;
            const angle = this.getRandom(2, 10);
            let ratio = this.getRandom(10, 30) * (this.getRandom(0, 1) ? 1 : -1);

            const getTranslateX = (diffTime) => {
                if (diffTime < this.scaleTime) {
                    return basicX;
                } else {
                    return basicX + ratio * Math.sin(angle * (diffTime - this.scaleTime));
                }
            };

            const getTranslateY = (diffTime) => {
                return image.height / 2 + (this.height - image.height / 2) * (1 - diffTime);
            };

            const fadeOutStage = this.getRandom(14, 18) / 100;

            const getAlpha = (diffTime) => {
                let left = 1 - +diffTime;
                if (left > fadeOutStage) {
                    return 1;
                } else {
                    return 1 - +((fadeOutStage - left) / fadeOutStage).toFixed(2);
                }
            };

            return (diffTime) => {
                if (diffTime >= 1) return true;
                context.save();
                const scale = getScale(diffTime);
                const translateX = getTranslateX(diffTime);
                const translateY = getTranslateY(diffTime);
                context.translate(translateX, translateY);
                context.scale(scale, scale);
                context.globalAlpha = getAlpha(diffTime);
                context.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height);
                context.restore();
            };
        },
        scan() {
            this.context.clearRect(0, 0, this.width, this.height);
            this.context.fillStyle = 'rgba(244, 244, 244, 0)';
            this.context.fillRect(0, 0, 200, 400);

            let index = 0;
            let length = this.renderList.length;

            if (length > 0) {
                this.scanning = true;
                requestAnimationFrame(this.scan.bind(this));
            } else {
                this.scanning = false;
            }

            while (index < length) {
                const child = this.renderList[index];
                if (!child || !child.render || child.render.call(null, (Date.now() - child.timestamp) / child.duration)) {
                    this.renderList.splice(index, 1);
                    length--;
                } else {
                    index++;
                }
            }
        },
        start() {
            const render = this.createRender();
            const duration = this.getRandom(1500, 3000);

            this.renderList.push({
                render,
                duration,
                timestamp: Date.now(),
            });

            if (!this.scanning) {
                this.scanning = true;
                requestAnimationFrame(this.scan.bind(this));
            }
        },
    },
};
</script>
  
<style scoped>
/* Add your styles here */
</style>
  