<template>
    <div id="map-container">
        <div id="wanglv">
        </div>
        <div id="liqian">
        </div>
    </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
    name: "map-view",
    mounted() {
        this.initAMap();
    },
    unmounted() {
        this.map?.destroy();
        this.map1?.destroy();
    },
    methods: {
        initAMap() {
            AMapLoader.load({
                key: "bb01c0b28c3307da93e32a9260b19ec2", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.map = new AMap.Map("wanglv", {
                        // 设置地图容器id
                        viewMode: "2D", // 是否为3D地图模式
                        zoom: 15, // 初始化地图级别
                        center: [105.81473, 25.964366], // 初始化地图中心点位置

                    });
                    this.map1 = new AMap.Map("liqian", {
                        // 设置地图容器id
                        viewMode: "2D", // 是否为3D地图模式
                        zoom: 14, // 初始化地图级别
                        center:  [107.618149,27.797729], // 初始化地图中心点位置

                    });

                    var marker = new AMap.Marker({
                        position: this.map.getCenter(),
                        icon: 'https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/map-icon-wanglv.png',
                        anchor: 'bottom-center',
                        offset: new AMap.Pixel(0, 0)
                    });

                    marker.setMap(this.map);

                    // 设置鼠标划过点标记显示的文字提示
                    marker.setTitle('新郎家');
                    // 设置label标签
                    // label默认蓝框白底左上角显示，样式className为：amap-marker-label
                    marker.setLabel({
                        direction: 'right',
                        offset: new AMap.Pixel(-40, 40),  //设置文本标注偏移量
                        content: "<div class='info'>新郎家</div>", //设置文本标注内容
                    });

                    var marker2 = new AMap.Marker({
                        position: this.map1.getCenter(),
                        icon: 'https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/map-icon-liqian.png',
                        anchor: 'bottom-center',
                        offset: new AMap.Pixel(0, 0)
                    });

                    marker2.setMap(this.map1);

                    // 设置鼠标划过点标记显示的文字提示
                    marker2.setTitle('新娘家');
                    // 设置label标签
                    // label默认蓝框白底左上角显示，样式className为：amap-marker-label
                    marker2.setLabel({
                        direction: 'right',
                        offset: new AMap.Pixel(-40, 40),  //设置文本标注偏移量
                        content: "<div class='info'>新娘家</div>", //设置文本标注内容
                    });

                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
<style scoped>
#map-container{
    display: flex;
    justify-content:center;
}
#wanglv {
    width: 48%;
    margin: 0 auto;
    height: 150px;
    border-radius: 25px;
    border: 2px solid rgba(148, 141, 144, 1);
}
#liqian {
    width: 48%;
    margin: 0 auto;
    height: 150px;
    border-radius: 25px;
    border: 2px solid rgba(148, 141, 144, 1);
}

>>>.amap-icon img {
    width: 40px;
    height: 40px;
    bottom: 0;
    position: relative !important;
    clear: both;
}

>>>.amap-logo {
    display: none !important;
}

>>>.amap-copyright {
    display: none !important;
}
</style>
  