<template>
    <div id="container2">
    </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
    name: "map-view",
    mounted() {
        this.initAMap();
    },
    unmounted() {
        this.map?.destroy();
    },
    methods: {
        initAMap() {
            AMapLoader.load({
                key: "bb01c0b28c3307da93e32a9260b19ec2", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.map = new AMap.Map("container2", {
                        // 设置地图容器id
                        viewMode: "2D", // 是否为3D地图模式
                        zoom: 16, // 初始化地图级别
                        center: [107.618149,27.797729], // 初始化地图中心点位置

                    });
                    var marker = new AMap.Marker({
                        position: this.map.getCenter(),
                        icon: 'https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/%E5%AE%9A%E4%BD%8D%E4%BD%8D%E7%BD%AE%E7%9B%AE%E7%9A%84%E5%9C%B0.png',
                        anchor: 'bottom-center',
                        offset: new AMap.Pixel(0, 0)
                    });

                    marker.setMap(this.map);

                    // 设置鼠标划过点标记显示的文字提示
                    marker.setTitle('贵州省安顺市镇宁县募役乡蜜蜂岩');
                    // 设置label标签
                    // label默认蓝框白底左上角显示，样式className为：amap-marker-label
                    marker.setLabel({
                        direction: 'right',
                        offset: new AMap.Pixel(-120, 40),  //设置文本标注偏移量
                        content: "<div class='info'>贵州省安顺市镇宁县募役乡蜜蜂岩</div>", //设置文本标注内容
                    });
                    
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
<style scoped>
#container2 {
    width: 90%;
    margin: 0 auto;
    height: 200px;
    border-radius: 45px;
    border: 2px solid rgba(148, 141, 144, 1);
}

>>>.amap-icon img {
    width: 40px;
    height: 40px;
    bottom: 0;
    position: relative !important;
    clear: both ;
}

>>>.amap-logo {
    display: none !important;
}

>>>.amap-copyright {
    display: none !important;
}
</style>
  