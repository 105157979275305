<template>
    <div class="gallery-container" @touchstart="startSwipe" @touchmove="swipe" @touchend="endSwipe">
      <div class="gallery" :style="{ transform: 'translateX(' + currentTranslateX + 'px)' }" ref="gallery">
        <div v-for="(image, index) in images" :key="index" class="gallery-item">
          <img :src="image" alt="Gallery Image">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        startSwipeX: 0,
        currentIndex: 0,
        images: [
        'https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9669.jpg',
          'https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9740.jpg',
          'https://wanglv.oss-cn-hangzhou.aliyuncs.com/assets/_MG_9809.jpg',
          // Add more image URLs as needed
        ],
      };
    },
    methods: {
      startSwipe(event) {
        this.startSwipeX = event.touches[0].clientX;
      },
      swipe(event) {
        event.preventDefault(); // 阻止默认的滑动行为
        const deltaX = this.startSwipeX - event.touches[0].clientX;
        this.$refs.gallery.style.transition = 'none'; // 立即响应滑动，取消过渡效果
        this.$refs.gallery.style.transform = `translateX(${-this.currentIndex * 100}%) translateX(${deltaX}px)`;
      },
      endSwipe(event) {
        const deltaX = this.startSwipeX - event.changedTouches[0].clientX;
        const threshold = this.$refs.gallery.clientWidth / 4; // 调整阈值
        this.$refs.gallery.style.transition = ''; // 恢复过渡效果
        
        if (deltaX > threshold) {
          this.nextImage();
        } else if (deltaX < -threshold) {
          this.prevImage();
        } else {
          this.$refs.gallery.style.transform = `translateX(${-this.currentIndex * 100}%)`;
        }
  
        // 添加过渡结束事件监听器
        this.$refs.gallery.addEventListener('transitionend', this.transitionEndHandler);
      },
      nextImage() {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
        this.$refs.gallery.style.transform = `translateX(${-this.currentIndex * 100}%)`;
      },
      prevImage() {
        this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
        this.$refs.gallery.style.transform = `translateX(${-this.currentIndex * 100}%)`;
      },
      // 过渡结束事件处理器
      transitionEndHandler() {
        // 移除过渡结束事件监听器
        this.$refs.gallery.removeEventListener('transitionend', this.transitionEndHandler);
        // 重新启用过渡效果
        this.$refs.gallery.style.transition = '';
      },
    },
  };
  </script>
  
  <style scoped>
  .gallery-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    touch-action: pan-y;
  }
  
  .gallery {
    display: flex;
  }
  
  .gallery-item {
    flex: 0 0 100%;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
  }
  </style>
  