<template>
    <div>
      <p v-if="isWeChat">正在配置微信JS-SDK权限...</p>
      <p v-else>不在微信中，无法使用微信JS-SDK。</p>
    </div>
  </template>
  
  <script>
  import wx from 'jweixin-module';
  import axios from 'axios';
  
  export default {
    data() {
      return {
        isWeChat: false,
      };
    },
    mounted() {
      this.checkWeChat();
    },
    methods: {
  
      // 判断是否在微信中
      checkWeChat() {
        const ua = navigator.userAgent.toLowerCase();
        this.isWeChat = ua.indexOf('micromessenger') !== -1;
  
        if (this.isWeChat) {
          // 如果在微信中，进行微信JS-SDK配置
          this.configWeChat();
        } else {
          // 如果不在微信中，可以进行其他处理
          console.log('Not in WeChat');
        }
      },
  
      generateNonceStr(length) {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          result += charset.charAt(randomIndex);
        }
        return result;
      },
  
      // 配置微信JS-SDK
      configWeChat() {
        const appId = 'wx5da16164a92a55de'; // 替换成你的微信公众号AppID
        const timestamp = Math.floor(new Date().getTime() / 1000);
        const nonceStr = this.generateNonceStr(16); // 替换成随机字符串
        const url = window.location.href.split('#')[0];
        // const url = window.location.href.split('#')[0]; // 当前页面的URL，注意去掉哈希部分
        alert('url' + url);
  
        // 调用后端接口获取签名等信息
        this.fetchSignature(url, appId, timestamp, nonceStr)
          .then((signature) => {
            wx.config({
              beta: true,
              debug: false,
              appId: appId,
              timestamp: timestamp,
              nonceStr: nonceStr,
              signature: signature,
              jsApiList: ["openLocation"], // 需要使用的微信JS-SDK接口，可以根据实际需求添加其他接口
            });
            console.log('signature', signature);
  
            wx.ready(() => {
              // 微信JS-SDK验证成功后的处理
              console.log('微信JS-SDK验证成功');
              alert('微信JS-SDK验证成功');
              this.launchMapNavigation();
            });
  
            wx.error((res) => {
              // 微信JS-SDK验证失败后的处理
              console.error('微信JS-SDK验证失败', res);
              alert('微信JS-SDK验证失败' + JSON.stringify(res));
            });
          })
          .catch((error) => {
            console.error('Failed to fetch signature:', error);
            alert('Failed to fetch signature:' + error);
          });
      },
  
      // 获取签名信息
      async fetchSignature(url, appId, timestamp, nonceStr) {
        try {
          const response = await axios.get(`https://WxSignature.wanglv.top/getSignature?url=${encodeURIComponent(url)}&timestamp=${encodeURIComponent(timestamp)}&nonceStr=${encodeURIComponent(nonceStr)}`);
          // const response = await axios.get(`http://192.168.110.23:8080/getSignature?url=${encodeURIComponent(url)}&timestamp=${encodeURIComponent(timestamp)}&nonceStr=${encodeURIComponent(nonceStr)}`);
          // const response = await axios.get(`https://WxSignature.wanglv.top/getSignature?url=${encodeURIComponent(url)}`);
          // WxSignature.wanglv.top
          alert("签名信息\r\n" + JSON.stringify(response));
          return response.data.signature;
        } catch (error) {
          throw new Error(`Failed to fetch signature1: ${error}`);
          alert("error" + error);
        }
      },
  
      // 拉起地图导航
      launchMapNavigation() {
        wx.openLocation({
          // 地理位置的坐标点，经纬度
          latitude: 25.964366,
          longitude: 105.81473,
          name: 'New York City',
          address: 'New York, NY, USA',
          scale: 14,
          success(res) {
            alert("openLocation-success:\r\n"+JSON.stringify(res));
          },
          fail(res) {
            alert("openLocation-fail:\r\n"+JSON.stringify(res));
          },
        });
  
      },
    },
  };
  </script>
  